export default function useRating() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const { $gettext } = useGettext();

  /********************
   * FUNCTIONS         *
   ********************/
  function mapRating(ratingAverage: number) {
    if (ratingAverage === 0) {
      return $gettext('Rate now');
    }

    if (ratingAverage < 1) {
      return $gettext('Bad');
    }

    if (ratingAverage >= 1 && ratingAverage < 2) {
      return $gettext('Sufficient');
    }

    if (ratingAverage >= 2 && ratingAverage < 3) {
      return $gettext('Inadequate');
    }

    if (ratingAverage >= 3 && ratingAverage < 4) {
      return $gettext('Good');
    }

    if (ratingAverage >= 4 && ratingAverage < 5) {
      return $gettext('Very good');
    }

    return $gettext('Outstanding');
  }

  return {
    mapRating,
  };
}
